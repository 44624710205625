class AggregatorSources {
    constructor(sources) {
        this.sources = {}
        this.processSources(sources);
    }

    processSources(sources_data) {
        if (!Array.isArray(sources_data)) {
            throw "Sources data is not a list";
        }
        for (let source_data of sources_data) {
            let source = new AggregatorSource(source_data);
            this.sources[source.url] = source;
        }
    }

    get(source_url) {
        return this.sources[source_url];
    }

    all() {
        let self = this;
        return Object.keys(this.sources).map(
            function(key){
                return self.sources[key];
            }
        );
    }
}

class AggregatorSource {
    constructor(source) {
        this.name = source[0];
        this.country_code = source[1];
        this.url = source[2];
    }
}


module.exports = {
    AggregatorSources, AggregatorSource,
}


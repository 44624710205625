import React from 'react'

class ActivitySkeleton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <li className="dss-card agg-activity-item agg-skeleton-activity">
                <h3 className="agg-skeleton-activity-name agg-skeleton-animated">
                    &nbsp;
                </h3>
                <div className="row">
                    <div className="col-xs-12 col-sm-9">
                        <p className="text-muted agg-skeleton-source-name agg-skeleton-animated">&nbsp;</p>
                        <div className="agg-skeleton-precis agg-skeleton-animated">
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-3">
                        <div className="agg-date-delta agg-skeleton-date agg-skeleton-animated">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

export default ActivitySkeleton;
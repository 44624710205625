import React from 'react'

class SearchFormSkeleton extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="row agg-search-inputs--skeleton agg-skeleton">
                <div className="col-sm-4">
                    <div className="form-group">
                        <div className="dss-text-brand-color agg-skeleton-animated agg-skeleton-label agg-skeleton-label-keyword">&nbsp;</div>
                        <div className="form-control agg-skeleton-animated agg-skeleton-input">&nbsp;</div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <div className="dss-text-brand-color agg-skeleton-animated agg-skeleton-label agg-skeleton-label-org">&nbsp;</div>
                        <div className="form-control agg-skeleton-animated agg-skeleton-input">&nbsp;</div>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="agg-search-button-container">
                        <button className="dss-btn dss-btn-primary dss-btn-sm agg-skeleton-animated agg-skeleton-button">&nbsp;</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchFormSkeleton;
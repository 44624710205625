import React from 'react'

class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.query_data = props.query_data;
        this.sources = props.sources;
    }
    render() {
        return (
            <div className="row agg-search-inputs">
                <div className="col-sm-4">
                    <div className="form-group">
                        <label htmlFor="search_text" className="dss-text-brand-color">Keyword (optional)</label>
                        <input type="text"
                            className="form-control"
                            placeholder=""
                            name="search_text"
                            id="search_text"
                            defaultValue={ this.query_data.search }
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="source" className="dss-text-brand-color">Organisation</label>
                        <select className="form-control" name="source" id="source" defaultValue={ this.query_data.organisation ? this.query_data.organisation.url : "" }>
                            <option value="">All</option>
                            { this.sources.all().map((source, key) => {
                                return (
                                    <option value={source.url} key={ "organisation-list-" + key }>
                                        { source.name }
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="agg-search-button-container">
                        <button className="dss-btn dss-btn-primary dss-btn-sm" type="submit">
                            <span className="fa fa-search"></span>&nbsp;
                            Search
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchForm;
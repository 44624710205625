import React from 'react'
import { Link } from "react-router-dom";


class Activity extends React.Component {
    constructor(props) {
        super(props);
    }

    getSourceName(source_url) {
        try {
            var name = this.props.sources.get(source_url).name;
            return name;
        } catch {
            return source_url;
        }
    }

    render() {
        return (
            <li className={"dss-card agg-activity-item activity-state-"+this.props.activity.status} data-activity-state={this.props.activity.state}>
                <h3>
                    <a href={ this.props.activity.url }>{this.props.activity.title}</a>
                </h3>
                <div className="row">
                    <div className="col-xs-12 col-sm-9">
                        <p className="text-muted">
                            <a 
                                target="_BLANK" 
                                href={ this.props.activity.source } 
                                title={"Visit " + this.getSourceName(this.props.activity.source) + "'s Citizen Space"}>
                                { this.getSourceName(this.props.activity.source) }
                            </a>
                        </p>
                        <p>
                            { this.props.activity.precis.trim() }
                            <a className="text-nowrap" href={ this.props.activity.url }>
                                <span className="fa fa-arrow-circle-right icon-space-right icon-space-left"></span>More
                                <span className="sr-only">about '{ this.props.activity.title }'</span>
                            </a>
                        </p>
                    </div>
                    <div className="col-xs-12 col-sm-3">
                        <div className="agg-date-delta">
                            <span className="text-muted">
                                { this.props.activity.dateDisplay(this.props.query_data.sort) }
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

export default Activity;
class AggregatorApi {
    constructor(base_url, is_test) {
        this.base_url = base_url;
        this.mocking = false;
        this.retry = true;
        if (is_test) {
            this.retry = false;
        }
        this.api_headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
    }

    wait(delay) {
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    async fetchWithRetries(url, options = {}, retries = 3, delay = 500) {
        // Attempt to fetch the URL, if it returns a failure, 
        // retry. If the total retries is exceeded, raise an error and try to 
        // report it to my Slack channel.
        function onError(err){
            if(!retries || !this.retry){
                throw err;
            }
            return this.wait(delay).then(() => this.fetchWithRetries(url, options, retries - 1, delay * 2));
        }
        let response = await fetch(url, options).catch(onError);
        let responseJSON = await response.json();
        // Check for the type of response data we need
        if (responseJSON.data) {
            return responseJSON;
        } else if (responseJSON.body) {
            return JSON.parse(responseJSON.body);
        }
        // If it's not there, try again if we have retries left
        if(!retries || !this.retry){
            // Do a fire and forget fetch to an AWS endpoint that will pass the error on to Slack
            let message = "Ran out of retries for " + url + " and the last response was " + JSON.stringify(responseJSON);
            fetch(
                this.base_url.replace("api/", "sentry"),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": "l5qGoGDJtt8HCYXfSq6kDaIoJhasHzCt66ZvPss9"
                    },
                    body: JSON.stringify({message: message })
                }

            )
            throw Error("Ran out of retries");
        }
        return this.wait(delay).then(() => this.fetchWithRetries(url, options, retries - 1, delay * 2));
    }

    parameteriseData(data) {
        // Take an object and turn it into a query string
        return new URLSearchParams(data).toString();
    }

    prep_query_url(query_data) {
        let url = this.base_url + "query";
        let data = {
            status: query_data.status,
            sort: query_data.sort,
            search: query_data.search, //.replaceAll('"', '\\"'),
            organisation: query_data.organisation ? query_data.organisation.url : "",
            page: query_data.page - 1,
            per_page: query_data.per_page
        };
        url = url + "?" + this.parameteriseData(data);
        return url;
    }

    prefetch_query(query_data) {
        let url = this.prep_query_url(query_data);
        if (this.mocking) {
            // Do nothing if we're mocking
            return;
        }
        // Fire and forget
        fetch(url, {
            headers: this.api_headers,
            method: "GET"
            //credentials: "include"
        });
    }

    async query(query_data) {
        let url = this.prep_query_url(query_data);
        if (this.mocking) {
            // Do nothing if we're mocking
            return {};
        }
        try {
            const responseJSON = await this.fetchWithRetries(url, {
                headers: this.api_headers,
                method: "GET"
                //credentials: "include"
            });
            return responseJSON;
        } catch (error) {
            console.error("Failed to fetch query", error);
            return null;
        }
    }

    prep_query_count_url(query_data) {
        let count_url = this.base_url + "query-count";
        let data = {
            status: query_data.status,
            sort: query_data.sort,
            search: query_data.search.replaceAll('"', '\\"'),
            organisation: query_data.organisation ? query_data.organisation.url : "",
            page: query_data.page - 1,
            per_page: query_data.per_page
        };
        count_url = count_url + "?" + this.parameteriseData(data);
        return count_url;
    }

    prefetch_query_count(query_data) {
        let count_url = this.prep_query_count_url(query_data);
        if (this.mocking) {
            // Do nothing if we're mocking
            return;
        }
        // Fire and forget
        fetch(count_url, {
            headers: this.api_headers,
            method: "GET"
            //credentials: "include"
        });
    }

    async query_count(query_data) {
        let count_url = this.prep_query_count_url(query_data);
        if (this.mocking) {
            // Do nothing if we're mocking
            return {};
        }
        try {
            // Count the total consultations
            const responseJSON = await this.fetchWithRetries(count_url, {
                headers: this.api_headers,
                method: "GET"
                //credentials: "include"
            });
            return responseJSON;
        } catch {
            console.warn("Failed to fetch query count");
            return null;
        }
    }

    async loadSources() {
        let url = this.base_url + "sources";
        if (this.mocking) {
            // Do nothing if we're mocking
            return {};
        }
        try {
            const responseJSON = await this.fetchWithRetries(url, {
                headers: this.api_headers,
                method: "GET"
                //credentials: "include"
            });
            return responseJSON;
        } catch {
            console.warn("Failed to fetch sources data");
            return null;
        }
    }

    async totalActivities() {
        let url = this.base_url + "activity-count";
        if (this.mocking) {
            // Do nothing if we're mocking
            return {};
        }
        try {
            const responseJSON = await this.fetchWithRetries(url, {
                headers: this.api_headers,
                method: "GET"
                //credentials: "include"
            });
            return responseJSON;
        } catch {
            console.warn("Failed to fetch activity count");
            return null;
        }
    }
}


module.exports = {
    AggregatorApi,
}


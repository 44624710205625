import React from 'react'

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.query_data = props.query_data;
        this.results = props.results;
        this.nextPage = props.nextPage;
        this.previousPage = props.previousPage;
        this.setPerPage = props.setPerPage;
    }
    showAnything() {
        return this.results.total > 3 || this.results.total > this.query_data.per_page;
    }
    showPerPage() {
        return this.results.total > 25;
    }
    render() {
        return (
            <>
            { this.showAnything() == false ? "" : (
                <>
                    <div className="row">
                        <div className="col-sm-5 col-xs-12">
                            <p className="agg-results-heading-pagination">
                                <span className="agg-search-results-count" dangerouslySetInnerHTML={{ __html: this.results.resultCountMessage(true) }}></span>
                                &nbsp;
                                <span className="agg-search-results-page-count">
                                    Page { this.results.currentPage() } of { this.results.totalPages() }
                                </span>
                            </p>
                        </div>
                        <div className="col-sm-7 col-xs-12">
                            <nav className="agg-pagination-widget">
                                { !this.results.onFirstPage() ? 
                                    (
                                        <a href="" onClick={ this.previousPage }>
                                            <span className={ this.results.previousPageClass() }>
                                                <span className="fa fa-chevron-circle-left icon-space-right"></span>Previous Page
                                            </span>
                                        </a>
                                    ) : (
                                        <span className={ this.results.previousPageClass() }>
                                            <span className="fa fa-chevron-circle-left icon-space-right"></span>Previous Page
                                        </span>
                                    )
                                }
                                <span className="hidden-xs"> | </span>
                                { !this.results.onLastPage() ? 
                                    (
                                        <a href="" onClick={this.nextPage }>
                                            <span className={ this.results.nextPageClass() }>
                                                Next Page
                                                <span className="fa fa-chevron-circle-right icon-space-left"></span>
                                            </span>
                                        </a>
                                    ) : (
                                        <span className={ this.results.nextPageClass() }>
                                            Next Page
                                            <span className="fa fa-chevron-circle-right icon-space-left"></span>
                                        </span>
                                    )
                                }
                            </nav>
                        </div>
                    </div>
                    { this.showPerPage() == false ? "" : (
                        <div className="row agg-per-page-selector">
                            <div className="col-xs-12">
                                <span className="dss-skip-small"></span>
                                <div className="dss-btn-group dss-btn-group-2 pull-right">
                                    <a 
                                        className={ this.query_data.per_page == 25 ? "dss-btn dss-btn-sm dss-btn-active agg-25-per-page" : "dss-btn dss-btn-sm agg-25-per-page" } 
                                        onClick={ this.setPerPage }
                                        href="" 
                                        data-per-page="25"
                                        data-preserve-scrolltop="1">
                                        25 per Page
                                    </a>
                                    <a 
                                        className={ this.query_data.per_page == 500 ? "dss-btn dss-btn-sm dss-btn-active agg-500-per-page" : "dss-btn dss-btn-sm agg-500-per-page" } 
                                        onClick={ this.setPerPage }
                                        data-per-page="500"
                                        href="" 
                                        data-preserve-scrolltop="1">
                                        500 per Page
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) }
                </>
            ) }
            </>
        );
    }
}

export default Pagination;
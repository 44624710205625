import React from 'react'

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.results = props.results;
        this.query_data = props.query_data;
        this.filter = props.filter;
        this.sort = props.sort;
    }
    render() {
        return (
            <div className="dss-rhino dss-rhino-greige dss-rhino-padding-top-5">
                <div className="container-fluid">
                    <span className="dss-skip-small"></span>
                    <nav className="row">
                        <div className="col-xs-12 col-sm-6">
                            <div className="dss-btn-group dss-btn-group-4">
                                <a className={ this.query_data.status == "all" ? "dss-btn dss-btn-active" : "dss-btn" }
                                    id="filter-option-all"
                                    href="" 
                                    onClick={this.filter}
                                    data-preserve-scrolltop="1"
                                    data-state="all">
                                    All
                                </a>
                                <a className={ this.query_data.status == "open" ? "dss-btn dss-btn-active" : "dss-btn" }
                                    id="filter-option-open"
                                    href=""
                                    onClick={this.filter}
                                    data-preserve-scrolltop="1"
                                    data-state="open">
                                    Open
                                </a>
                                <a className={ this.query_data.status == "closed" ? "dss-btn dss-btn-active" : "dss-btn" }
                                    id="filter-option-closed"
                                    href=""
                                    onClick={this.filter}
                                    data-preserve-scrolltop="1"
                                    data-state="closed">
                                    Closed
                                </a>
                                <a className={ this.query_data.status == "forthcoming" ? "dss-btn dss-btn-active" : "dss-btn" }
                                    id="filter-option-forthcoming"
                                    href=""
                                    onClick={this.filter}
                                    data-preserve-scrolltop="1"
                                    data-state="forthcoming">
                                    Forthcoming
                                </a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div id="agg-sort-order-box" className="form-inline">
                                <label htmlFor="custom-sort" className={ this.results.total == 0 ? "text-muted" : ""}>Sort by:</label>&nbsp;
                                <select 
                                    className="form-control" 
                                    name="custom_sort" 
                                    id="custom-sort" 
                                    disabled={ this.results.total == 0 }
                                    defaultValue={ this.query_data.sort }
                                    onChange={ this.sort }
                                    >
                                    { this.query_data.status == 'all' && (
                                        <>
                                            <option value="newest_update">Newest update</option>
                                            <option value="oldest_update">Oldest update</option>
                                        </>
                                    )}
                                    { this.query_data.status == 'open' && (
                                        <>
                                            <option value="most_recently_opened">Opened recently</option>
                                            <option value="closing_soonest">Closing soon</option>
                                        </>
                                    )}
                                    { this.query_data.status == 'closed' && (
                                        <>
                                            <option value="newest_update">Recently closed</option>
                                            <option value="oldest_update">Closed for longest</option>
                                        </>
                                    )}
                                    { this.query_data.status == 'forthcoming' && (
                                        <>
                                            <option value="newest_update">Opening soon</option>
                                            <option value="oldest_update">Opening later</option>
                                        </>
                                    )}
                                </select>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

export default Filter;
import React from 'react';
import { Outlet, Link } from "react-router-dom";

const Footer = () => {
    let year = new Date().getFullYear();
    return (
        <footer id="global-footer-wrapper">
            <div id="global-footer" className="container-fluid agg-footer">
                <div className="row">
                    <div className="col-sm-8 col-xs-12">
                    <p className="agg-footer-colophon">
                        <span>
                            Citizen Space Aggregator is a companion to <a href="http://www.citizenspace.com">Citizen Space</a> from <a href="http://www.delib.net">Delib</a>
                        </span>
                    </p>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <p className="agg-footer-copyright">
                            <span>&copy; { year }</span> <span><a href="http://www.delib.net">Delib Limited</a></span> <span className="icomoon-delib icon-space-left"></span>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;
import React from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";

import aggregator_logo from "../../static/img/cs_aggregator_logo@2x.png";

class Header extends React.Component {
    render () {
        return (
            <header>
                <nav className="navbar navbar-default navbar-inverse agg-navbar">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <a className="navbar-brand navbar-left" href="/">
                                <img src={aggregator_logo} width="240" height="22" alt="Citizen Space Aggregator" style={{ display: "inline-block" }} />
                            </a>
                        </div>
                        <p className="navbar-text navbar-right">
                            <a href="http://www.citizenspace.com">
                                <span className="fa fa-arrow-circle-right icon-space-right"></span>What is Citizen Space?
                            </a>
                        </p>
                    </div>
                </nav>
            </header>
        )
    }
};

export default Header;
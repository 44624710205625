import 'regenerator-runtime'
import React from 'react'
import ReactDOM from "react-dom";
import {
    BrowserRouter, Routes,
    Route,
    NavLink,
    HashRouter,
    useNavigate,
    useParams
} from "react-router-dom";


/**
 * <meta name="viewport" content="width=device-width, initial-scale=1">
        <link href="/static/dlb-public-ui/css/bootstrap.css" rel="stylesheet" type="text/css" />
        <link href="/static/dlb-public-ui/css/dlb-public-ui.css" rel="stylesheet" type="text/css" />
        <link href="/static/css/aggregator.css" rel="stylesheet" type="text/css" />
        <tal:cs condition="request.registry.settings['app'] == 'cs'">
            <link href="/static/css/citizenspace.css" rel="stylesheet" type="text/css" />
        </tal:cs>
        <tal:da condition="request.registry.settings['app'] == 'da'">
            <link href="/static/css/dialogue.css" rel="stylesheet" type="text/css" />
        </tal:da>
        <!--! Lato is currently brought in from Google's CDN, we're trusting Google with this one -->
        <link href="https://fonts.googleapis.com/css?family=Lato:400,300,700,400italic" rel="stylesheet" type="text/css">
        <script type="text/javascript" src="/static/dlb-public-ui/libraries/jquery/jquery.js"></script>
        <!--! html5shiv enables html5 elements in IE 8 and older -->
        <!--[if lt IE 9]>
                <script type="text/javascript" src="/static/dlb-public-ui/libraries/html5shiv/dist/html5shiv.min.js"></script>
        <![endif]-->
        <!--! font awesome and ico-moon -->
        <link rel="stylesheet" href="/static/dlb-public-ui/libraries/font-awesome/css/font-awesome.min.css" />
        <link type="text/css" rel="stylesheet" media="all" href="/static/ico-moon/style.css" />
        <!--! Respond.js for media query support in IE 8, needs to be loaded immediately after all CSS-->
        <script type="text/javascript" src="/static/dlb-public-ui/libraries/respond/dest/respond.min.js"></script>
        <!--/Respond.js-->
 */
import './static/dlb-public-ui/libraries/font-awesome/css/font-awesome.min.css';
import './static/ico-moon/style.css';
import './static/dlb-public-ui/css/bootstrap.css';
import './static/dlb-public-ui/css/dlb-public-ui.css';
import './static/css/aggregator.css';
import './static/css/citizenspace.css';

import Search from "./pages/Search";

import Header from "./pages/components/Header";
import Footer from "./pages/components/Footer";

function Aggregator() {

    return (
        <BrowserRouter>
            <div id="global-wrapper">
                <Header />
                <main id="page-content-wrapper" className="page-content-wrapper">
                    <div className='content'>
                        <Routes>
                            <Route path="/" >
                                <Route index element={<Search />} />
                            </Route>
                        </Routes>
                    </div>
                </main>
            </div>
            <Footer />
        </BrowserRouter>
    );
}

export default Aggregator;
import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Routes,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import Aggregator from './Aggregator'

// Don't know whether this needs wrapping in anything
ReactDOM.render(
    <React.StrictMode>
    <Aggregator />
    </React.StrictMode>,
    document.getElementById('root')
);



